import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import obstetrics from "../../practices_images/Obstetrics.jpg"; // Ensure this path is correct

const Obstetrics = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
      <div className="cap-container">
        <h1>Obstetrics and Gynecology</h1>

        <div className="cap-section">
          <div className="text-content">
            <p>
              OB/GYN practices require comprehensive solutions to manage the full spectrum of women's health services — from routine exams and prenatal care to complex surgical procedures. Our platform enhances scheduling, streamlines documentation for ultrasounds, screenings, and procedures like hysteroscopies and cesarean deliveries, while supporting seamless coordination across care teams. With OB/GYN-specific EMR templates and coding workflows, we help reduce claim denials, ensure accurate billing, and improve both clinical efficiency and patient outcomes.
            </p>
            {/* <button className="cap-button">Learn More</button> */}
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={obstetrics} alt="Obstetrics" />
          </div>

        </div>
      </div>
    </motion.div>
  );
};

export default Obstetrics;
