import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Pages.css";
import "./Capabilities.css";
import hema from "../../practices_images/hematology.jpg"; // Ensure this path is correct

const Hematology = () => {
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>Hematology</h1>

        <div className="cap-section">
          <div className="text-content">
            <p>
              Hematology practices manage complex patient cases involving blood disorders, cancer care, and chronic conditions that require precise documentation and ongoing monitoring. Our tailored solutions support the unique needs of hematologists by streamlining lab integration, infusion tracking, and treatment planning. With built-in support for hematology-specific coding and compliance, we help ensure accurate reimbursements while minimizing administrative burden — allowing providers to focus on delivering high-quality, life-saving care.
            </p>
            {/* <button className="cap-button">Learn More</button> */}
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={hema} alt="Hematology" />
          </div>

        </div>
      </div>
    </motion.div>
  );
};

export default Hematology;
