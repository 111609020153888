import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import endo from "../../practices_images/Endocrinology.jpg"; // Ensure this path is correct

const Endocrinology = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
      <div className="cap-container">
        <h1>Endocrinology</h1>

        <div className="cap-section">
          <div className="text-content">
            <p>
              Endocrinology practices deal with intricate chronic conditions such as diabetes, thyroid disorders, and hormonal imbalances that require ongoing monitoring and personalized treatment plans. Our healthcare solutions are designed to simplify care coordination, integrate seamlessly with diagnostic labs, and support endocrine-specific workflows. From automated insulin management tracking to accurate ICD-10 coding for hormonal disorders, we help optimize clinical efficiency, improve patient outcomes, and ensure smooth revenue cycle management.
            </p>
            {/* <button className="cap-button">Learn More</button> */}
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={endo} alt="Endocrinology" />
          </div>

        </div>
      </div>
  </motion.div>
  );
};

export default Endocrinology;
