import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
/* import { useNavigate } from "react-router-dom"; */

import "./Pages.css";

const Quantisys360AI = () => {
  /* const navigate = useNavigate(); */
  
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="page-container">
        <h1>Quantisys360 - Gen AI</h1>

        <h2>Coming Soon!</h2>

        {/* <button className="page-button" onClick={() => navigate("/learn-more/quantisys360")}>Learn More</button> */}
      </div>
    </motion.div>
  );
};

export default Quantisys360AI;
